import React, { Component } from 'react';
import { Text, View, TextInput, TouchableOpacity, ScrollView,StyleSheet, Dimensions } from 'react-native';
// import BottomNavigation, { FullTab} from 'react-native-material-bottom-navigation';

var { width ,height} = Dimensions.get("window")

// import Components
import Food from './Food'
import Cart from './Cart'
// unable console yellow
console.disableYellowBox = true;
// import icons
import Icon from 'react-native-vector-icons/Ionicons';
// import Toast, {DURATION} from 'react-native-easy-toast'


export default class Inicio extends Component {

  constructor(props) {
     super(props);
     this.state = {
        module:1,
     };

   //  this.tabs = [
   //     {key: 1,
   //       icon: 'md-restaurant',
   //       label:'Comida',
   //       barColor: '#388E3C',
   //       pressColor: 'rgba(255, 255, 255, 0.16)'
   //     },
   //     {
   //     key: 2,
   //     icon: 'md-basket',
   //     label: 'Carrito',
   //     barColor: '#B71C1C',
   //     pressColor: 'rgba(255, 255, 255, 0.16)'
   //   }
   // ];
  }

  // renderIcon = icon => ({ isActive }) => (
  //   <Icon size={24} color="white" name={icon} />
  // )
  //
  // renderTab = ({ tab, isActive }) => (
  //   <FullTab
  //     isActive={isActive}
  //     key={tab.key}
  //     label={tab.label}
  //     renderIcon={this.renderIcon(tab.icon)}
  //   />
  // )

  render() {
    return (
      <View style={{flex:1}}>
          <ScrollView style={{width: width, height: height - 60}}>
            {
             this.state.module==1? <Food />
             :<Cart />
            }
          </ScrollView>
         <View style={styles.bottomTab}>
           <TouchableOpacity style={styles.itemTab} onPress={()=>this.setState({module:1})}>
             <Icon name="md-restaurant" size={30} color={this.state.module==1?"#900":"gray"} />
             <Text>Comida</Text>
           </TouchableOpacity>
           <TouchableOpacity style={styles.itemTab} onPress={()=>this.setState({module:2})}>
             <Icon name="md-basket" size={30} color={this.state.module==2?"#900":"gray"} />
             <Text>Carrito</Text>
           </TouchableOpacity>
         </View>
       </View>
    );
  }
}

const styles = StyleSheet.create({
  bottomTab:{
    // flex: .1,
    // position: 'relative',
    // bottom: 0,
    width: width,
    height: 60,
    backgroundColor:'orange',
    flexDirection:'row',
    justifyContent:'space-between',
    elevation:8,
    shadowOpacity:0.3,
    shadowRadius:50,
  },
  itemTab:{
    width:width/2,
    backgroundColor:'white',
    alignItems:'center',
    justifyContent:'center'
  }
})
