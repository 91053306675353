import React, { Component } from 'react';
import { Text,
  FlatList,
  Image,
  StyleSheet,
  Dimensions,
  View,
  ScrollView,
  TextInput,
   Alert,
  TouchableOpacity
} from 'react-native';
var {height, width } = Dimensions.get('window');
import Swiper from 'react-native-swiper/src';
// import AutoScrollFlatList from "react-native-autoscroll-flatlist";
// import Toast, {DURATION} from 'react-native-easy-toast'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {cartas} from '../public/global';
// import AsyncStorage
import AsyncStorage from '@react-native-community/async-storage';
// import icons
import Icon from 'react-native-vector-icons/Ionicons';
// import { CheckBox } from 'react-native-elements';
// import Modal from 'react-native-modal';


toast.configure();


export default class Food extends Component {


  constructor(props)
  {
    super(props);
    this.state = {
      dataCategories:[],
      dataFood:[],
      selectCat:2
    }
    this.indiceCategoria =1;
  }

  onClickAddCart(data){
   const itemcart = {
     food: data,
     quantity:  1,
     price: data.price
   }
   AsyncStorage.getItem('cart').then((datacart)=>{
       if (datacart !== null) {
         // We have data!!
         const cart = JSON.parse(datacart)
         const i = cart.findIndex( element => element.food.name == itemcart.food.name  );
         if(i != -1){
           // console.log('index '+i+JSON.stringify(cart));
           cart[i].quantity += 1;
         }else{
           cart.push(itemcart)
         }
         AsyncStorage.setItem('cart',JSON.stringify(cart));
       }
       else{
         const cart  = []
         cart.push(itemcart)
         AsyncStorage.setItem('cart',JSON.stringify(cart));
       }
       //console.log('Se agrego '+itemcart.food.name);
       toast.success('Se agrego '+itemcart.food.name,{
         position: toast.POSITION.BOTTOM_LEFT,
         autoClose: 1000
       });
     })
     .catch((err)=>{
       alert(err)
     })

  }

  onClickMoveIcon(indice){
    this.indiceCategoria += indice;
    if(this.indiceCategoria == -1)
      this.indiceCategoria = 9;
    if(this.indiceCategoria == 10)
      this.indiceCategoria = 0;

    this.ListCategoria_Ref.scrollToIndex({ index: this.indiceCategoria || 0 });
    // this.ListCategoria_Ref.scrollToIndex({animated:true,index: this.state.indiceCategoria});
    // this.ListCategoria_Ref.flashScrollIndicators();
    // console.log("prueba, apreto"+this.indiceCategoria);
  }

  componentDidMount(){
    this.setState({
      isLoading: false,
      dataCategories: cartas.categories,
      dataFood:cartas.food
    });
  }

  _renderItem(item){
    return(
      <TouchableOpacity style={[styles.divCategorie,{backgroundColor:item.color}]}
      onPress={()=>{this.setState({selectCat: item.id})}}>
        <Image
          style={{width:100,height:80}}
          resizeMode="contain"
          source={ item.image} />
        <Text style={{fontWeight:'bold',fontSize:22}}>{item.name}</Text>
      </TouchableOpacity>
    )
  }

  _renderItemFood(item){
    let catg = this.state.selectCat;
    if(catg==0 || catg==item.categorie)
    {
        // console.log("catg:"+catg+" ,categorie:"+item.categorie+", item;"+item.codigo);
        return(
        <TouchableOpacity style={styles.divFood}   >
          <Image
            style={styles.imageFood}
            resizeMode="contain"
            source={item.image} />
            <View style={{height:((width/2)-20)-90, backgroundColor:'transparent', width:((width/2)-20)-10}} />
            <Text style={{fontWeight:'bold',fontSize:22,textAlign:'center'}}>
              {item.name}
            </Text>
            <Text style={{fontSize:15,color:"black"}}>{item.descripcion}</Text>
            <Text style={{fontSize:20,color:"green"}}>Gs{item.price}</Text>
            <TouchableOpacity
              onPress={()=>this.onClickAddCart(item)}
              style={{
                width:(width/2)-40,
                backgroundColor:'#33c37d',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:"center",
                borderRadius:5,
                padding:4
              }}>
              <Text style={{fontSize:18, color:"white", fontWeight:"bold"}}>Agregar</Text>
              <View style={{width:10}} />
              <Icon name="ios-add-circle" size={30} color={"white"} />
            </TouchableOpacity>
          </TouchableOpacity>
        )
    }
  }



  render() {
    return (
        <View style={{ backgroundColor:"#f2f2f2" }}>
          <View style={{width: width, alignItems:'center'}} >
              <Image style={{height:90,width:width/2,margin:5}} resizeMode="contain" source={require("../image/logoSukiyakiC.jpg")}  />
              <Text style={{textAlign: 'left'}}>Tel:203037 o 0983-918643</Text>
              <Text style={{textAlign: 'left'}}>Lunes a Jueves de 11:30--14:15 y 18:30--21:45</Text>
              <Text>Viernes y Sabado de 11:30--14:15 y 18:30--22:15</Text>
          </View>

          <View style={{width:width ,borderRadius:20, paddingVertical:20, backgroundColor:'white'}}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between',padding: 10}}>
              <Icon name="ios-arrow-back" size={25} color={"black"} onPress={()=>this.onClickMoveIcon(-1)} />
              <Text style={{fontSize:25, color:"black", fontWeight:"bold"}}>Categoria</Text>
              <Icon name="ios-arrow-forward" size={25} color={"black"} onPress={()=>this.onClickMoveIcon(1)} />
            </View>
            <FlatList
            style={{maxHeight: 200, minHeight: 150}}
            ref={(ref) => {
                this.ListCategoria_Ref = ref;
              }}
              horizontal={true}
              data={this.state.dataCategories}
              renderItem={({ item }) => this._renderItem(item)}
              keyExtractor = { (item,index) => index.toString() }
            />
            <FlatList
              style={{flex: 1,width: width,padding: 5}}
              data={this.state.dataFood}
              numColumns={2}
              initialNumToRender={10}
              renderItem={({ item }) => this._renderItemFood(item)}
              keyExtractor = { (item,index) => index.toString() }
            />
            <View style={{height:20}} />
          </View>
        </View>

    );
  }
};

const styles = StyleSheet.create({
  divCategorie:{
    backgroundColor:'red',
    margin:5, alignItems:'center',
    borderRadius:10,
    padding:10
  },
  titleCatg:{
    fontSize:27,
    fontWeight:'bold',
    textAlign:'center',
    marginBottom:5
  },
  imageFood:{
    width:((width/2)-20)-10,
    height:((width/2)-20)-30,
    backgroundColor:'transparent',
    position:'absolute',
    top:-45
  },
  divFood:{
    width:(width/2)-20,
    padding:10,
    borderRadius:10,
    marginTop:55,
    marginBottom:5,
    marginLeft:10,
    alignItems:'center',
    elevation:8,
    shadowOpacity:0.3,
    shadowRadius:50,
    backgroundColor:'white',
  },
  text: {
    fontSize: 14
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  }
});
