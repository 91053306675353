import React, { Component } from 'react';
import { View} from 'react-native';


import Inicio from "./src/Index"

export default class Index extends Component {

  constructor(props) {
     super(props);
     this.state = {
       data:"",
     };
  }

  render() {
    return (
      <View>
          <Inicio />
      </View>
    );
  }
}
