import React, { Component } from 'react';
import { Text, View,ScrollView, TextInput, Image, TouchableOpacity, StyleSheet, Dimensions,Linking } from 'react-native';
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from 'react-native-simple-radio-button';
// import NumericInput from 'react-native-numeric-input';


var { width } = Dimensions.get("window")
// import icons
import Icon from 'react-native-vector-icons/Ionicons';
import AsyncStorage from '@react-native-community/async-storage';

export default class Cart extends Component {

  constructor(props) {
     super(props);
     this.state = {
       dataCart:[],
       total:0,
       ruc:"",
       nombre:"",
       direccion:"",
       nro_telefono:"",
       observacion:"",
       deliv_select:0,
       delivery:[
           {label: 'delivery ', value: 0 },
           {label: 'retirar', value: 1 }
         ],
       pago_select:0,
       metodo_pago:[
           {label: 'efectivo ', value: 0 },
           {label: 'tarjeta ', value: 1 },
           {label: 'transferencia', value: 2 }
         ],
       cant_palito:0
     };
  }

  componentDidMount()
   {
     AsyncStorage.getItem('cart').then((cart)=>{
       if (cart !== null) {
         // We have data!!
         const cartfood = JSON.parse(cart);
         this.setState({dataCart:cartfood});
         let sum = this.state.dataCart.reduce((result,item) => result+(item.price * item.quantity),0);
         this.setState({total: sum});
       }
     })
     .catch((err)=>{
       alert(err)
     });
     AsyncStorage.getItem('profile').then((prof)=>{
       if (prof !== null) {
         // We have data!!
         const perfil = JSON.parse(prof);
         this.setState({
           ruc:perfil.ruc,
           nombre:perfil.nombre,
           direccion: perfil.direccion,
           nro_telefono: perfil.nro_telefono,
           observacion: perfil.observacion,
           pago_select: perfil.pago_select,
           deliv_select: perfil.deliv_select,
           cant_palito:perfil.cant_palito
         });
       }
     })
     .catch((err)=>{
       alert(err)
     });
   }

   onChangeQual(i,type)
    {
      const dataCar = this.state.dataCart
      let cantd = dataCar[i].quantity;

      if (type) {
       cantd = cantd + 1
       dataCar[i].quantity = cantd
       this.setState({dataCart:dataCar})
      }
      else if (type==false&&cantd>=2){
       cantd = cantd - 1
       dataCar[i].quantity = cantd
       this.setState({dataCart:dataCar})
      }
      else if (type==false&&cantd==1){
       dataCar.splice(i,1)
       this.setState({dataCart:dataCar})
      }
      AsyncStorage.getItem('cart').then((datacart)=>{
            AsyncStorage.setItem('cart',JSON.stringify(dataCar));
        })
        .catch((err)=>{
          alert(err)
        })
      this.setState({total: this.state.dataCart.reduce((result,item) => result+(item.price * item.quantity),0)});

    }

  limpiarGuardarDatos(){
      const cart  = []
      AsyncStorage.setItem('cart',JSON.stringify(cart));
      const datos  = {
        ruc:this.state.ruc,
        nombre:this.state.nombre,
        direccion: this.state.direccion,
        nro_telefono:this.state.nro_telefono,
        observacion: this.state.observacion,
        deliv_select:this.state.deliv_select,
        pago_select:this.state.pago_select,
        cant_palito:this.state.cant_palito
      }
      AsyncStorage.setItem('profile',JSON.stringify(datos));
  }

  onCheckOut()
  {
    if(this.state.nombre == "" || this.state.nro_telefono == ""
    || (this.state.deliv_select == 0 &&  this.state.direccion == ""  )   ){
      alert("Campos incompletos, complete por favor");
      return;
    }
    if(this.state.total == 0){
      alert("Tiene que cargar los pedidos");
      return;
    }
    let suma = 0;
    let cadena = "https://wa.me/595983918643?text=Pedido%20Rest.%20Sukiyaki%0A%0A";
    cadena += this.state.dataCart.map((item,key) => item.food.codigo+"%20"+item.quantity+"%20Gs"+(item.price*item.quantity)+"%20"+item.food.name+"%0A");
    cadena = cadena.replace(/,/g, '');
    cadena += "--------------------------------------";
    cadena += "%0ATotal en Gs."+this.state.total;
    cadena += "%0A%0ARuc:"+this.state.ruc+"%0ANombre:"+this.state.nombre;
    cadena += "%0ADireccion:"+this.state.direccion+"%0ATel:"+this.state.nro_telefono;
    cadena += "%0AObs:"+this.state.observacion;
    cadena += "%0ACantPalito:"+this.state.cant_palito;
    if(this.state.deliv_select == 0){
      cadena += "%0Apara delivery 10.000Gs adicional";
    }else{
      cadena += "%0Apara retirar"
    }
    if(this.state.pago_select == 0){
      cadena += "%0Aforma de pago: Efectivo";
    }else if(this.state.pago_select == 1){
      cadena += "%0Aforma de pago: Tarjeta";
    }else{
      cadena += "%0Aforma de pago: transferencia";
    }
    // alert(cadena);
    this.limpiarGuardarDatos();

    Linking.openURL(cadena);
  }

   render() {
    return (
      <View style={{flex:1,alignItems: 'center', justifyContent: 'center'}}>
         <View style={{height:20}} />
         <Text style={{fontSize:32,fontWeight:"bold",color:"#33c37d"}}>Cart food</Text>
         <View style={{height:10}} />

         <View style={{flex:1}}>

           <ScrollView>

             {
               this.state.dataCart.map((item,i)=>{
                 return(
                   <View style={{width:width-20,margin:10,backgroundColor:'transparent', flexDirection:'row', borderBottomWidth:2, borderColor:"#cccccc", paddingBottom:10}}>
                     <Image resizeMode={"contain"} style={{width:width/3,height:width/3}} source={item.food.image} />
                     <View style={{flex:1, backgroundColor:'trangraysparent', padding:10, justifyContent:"space-between"}}>
                       <View>
                         <Text style={{fontWeight:"bold", fontSize:20}}>{item.food.codigo+" "+item.food.name}</Text>
                         <Text></Text>
                       </View>
                       <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                         <Text style={{fontWeight:'bold',color:"#33c37d",fontSize:20}}>Gs{item.price*item.quantity}</Text>
                         <View style={{flexDirection:'row', alignItems:'center'}}>
                           <TouchableOpacity onPress={()=>this.onChangeQual(i,false)}>
                             <Icon name="ios-remove-circle" size={35} color={"#33c37d"} />
                           </TouchableOpacity>
                           <Text style={{paddingHorizontal:8, fontWeight:'bold', fontSize:18}}>{item.quantity}</Text>
                           <TouchableOpacity onPress={()=>this.onChangeQual(i,true)}>
                             <Icon name="ios-add-circle" size={35} color={"#33c37d"} />
                           </TouchableOpacity>
                         </View>
                       </View>
                     </View>
                   </View>
                 )
               })
             }

             <View style={{height:20}} />
             <View>
               <Text style={style.textosDerecha}>Total: Gs {this.state.total.toString()}</Text>
             </View>
             <Text style={style.textosCentrados} >Datos a Completar</Text>
             <RadioForm
                style={style.textinput}
                radio_props={this.state.delivery}
                initial={this.state.deliv_select}
                formHorizontal={true}
                animation={true}
                onPress={(value) => {this.setState({deliv_select:value})}}
              />
             <TextInput
              style={style.textinput}
              placeholder="Nombre o Razon Social *"
              onChangeText={text => this.setState({nombre: text })}
              value={this.state.nombre}
            />
            <TextInput
               style={style.textinput}
               placeholder="Ruc"
               onChangeText={text => this.setState({ruc: text })}
               value={this.state.ruc}
           />
           <TextInput
              style={style.textinput}
              placeholder="Direccion con nro de casa (obligatorio si es delivery)"
              onChangeText={text => this.setState({direccion: text })}
              value={this.state.direccion}
            />
          <TextInput
               style={style.textinput}
               placeholder="Celular *"
               onChangeText={text => this.setState({nro_telefono: text })}
               value={this.state.nro_telefono}
           />
           <View style={style.textAreaContainer} >
              <TextInput
                style={style.textArea}
                underlineColorAndroid="transparent"
                placeholder="Observacion"
                placeholderTextColor="grey"
                numberOfLines={10}
                multiline={true}
                onChangeText={text => this.setState({observacion: text })}
                value={this.state.observacion}
              />
          </View>
          <RadioForm
              style={style.textinput}
              radio_props={this.state.metodo_pago}
              initial={this.state.pago_select}
              formHorizontal={true}
              animation={true}
              onPress={(value) => {this.setState({pago_select:value})}}
            />
            <TextInput
                 style={style.textinput}
                 placeholder="Cant Palito"
                 onChangeText={text => this.setState({cant_palito: text })}
                 value={this.state.cant_palito}
             />
           <Text style={{fontSize: 14, fontWeight: 'bold', alignContent: 'center', textAlign: 'left',margin: 5}} >Funciona solamente desde el celular, no tablet ni pc</Text>
           <TouchableOpacity
                onPress={()=>this.onCheckOut()}
                style={{
                 backgroundColor:"#33c37d",
                 width:width-40,
                 alignItems:'center',
                 padding:10,
                 borderRadius:5,
                 margin:20
               }}>
               <Text style={{
                   fontSize:24,
                   fontWeight:"bold",
                   color:'white'
                 }}>
                 ORDENAR
               </Text>
             </TouchableOpacity>

             <View style={{height:20}} />
           </ScrollView>

         </View>

      </View>
    );
  }
}

const style = StyleSheet.create({
  textinput:{
    fontSize: 22,
    alignSelf: 'stretch',
    height: 40,
    marginBottom: 30,
    color: '#0B0A0A',
    borderBottomColor: '#f8f8f8',
    borderBottomWidth: 1,
    marginLeft: 10,
    marginRight: 10,
    padding: 8
  },
  textAreaContainer: {
    borderColor: "#D0D0D0",
    borderWidth: 1,
    padding: 5,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 5
  },
  textArea: {
    fontSize: 22,
    height: 150,
    justifyContent: "flex-start"
  },
  textosCentrados:{
    fontSize: 20,
    fontWeight: 'bold',
    alignContent: 'center',
    textAlign: 'center'
  },
  textosDerecha:{
    fontSize: 22,
    fontWeight: 'bold',
    alignContent: 'center',
    textAlign: 'right',
    margin: 10
  }
});
