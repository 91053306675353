export const cartas = {
  "categories": [
    {
      "id": 1,
      "name": "Sushi",
      "color": "#fbc831",
      "image": require("../image/sushi/B25.png")
    },
    {
      "id": 2,
      "name": "Nabe",
      "color": "#9fd236",
      "image": require("../image/nabe/A.png")
    },
    {
      "id": 3,
      "name": "Bocados",
      "color": "orange",
      "image": require("../image/bocadito/75.png")
    },
    {
      "id": 4,
      "name": "Fideos",
      "color": "#f2f2f2",
      "image": require("../image/fideo/39.png")
    },
    {
      "id": 5,
      "name": "Arroz",
      "color": "#E959F8",
      "image": require("../image/arroz/14.png")
    },
    {
      "id": 6,
      "name": "Carnes",
      "color": "#D34433",
      "image": require("../image/carne/8.png")
    },
    {
      "id": 7,
      "name": "Vegetales",
      "color": "#D4F859",
      "image": require("../image/verdura/65A.png")
    },
    {
      "id": 8,
      "name": "Seafood",
      "color": "#59EEF8",
      "image": require("../image/pescado/45S.png")
    },
    {
      "id": 9,
      "name": "Kids",
      "color": "#F5FF60",
      "image": require("../image/kids/12.jpg")
    },
    {
      "id": 10,
      "name": "Bebidas",
      "color": "#FBA1DD",
      "image": require("../image/bebida/coca.png")
    }
  ],
  "food": [
    {
      "name": "Barco Matsu",
      "price": 200000,
      "categorie": 1,
      "codigo":"BM",
      "descripcion":"42 piezas de sushi, 2 sopa de miso",
      "image": require("../image/sushi/BM.png")
    },
    {
      "name": "Barco Take",
      "price": 125000,
      "categorie": 1,
      "codigo":"BT",
      "descripcion":"26 piezas de sushi, 1 sopa de miso",
      "image": require("../image/sushi/BT.png")
    },
    {
      "name": "Barco Ume",
      "price": 100000,
      "categorie": 1,
      "codigo":"BU",
      "descripcion":"24 piezas de sushi, 1 sopa de miso",
      "image": require("../image/sushi/BU.png")
    },
    {
      "name": "Filadelfia Roll",
      "price": 42000,
      "categorie": 1,
      "codigo":"B25",
      "descripcion":"10 unid. Salmon crudo, aguacate, queso crema",
      "image": require("../image/sushi/B25.png")
    },
    {
      "name": "Aburi Roll",
      "price": 54000,
      "categorie": 1,
      "codigo":"B31",
      "descripcion":"10 unid. Langostino, queso crema, salmon flameado",
      "image": require("../image/sushi/B31.png")
    },
    {
      "name": "Ebi Abo Roll",
      "price": 49000,
      "categorie": 1,
      "codigo":"B32",
      "descripcion":"10 unid. langostino, queso crema, aguacate, salsa picante",
      "image": require("../image/sushi/B32.png")
    },
    {
      "name": "New York Roll",
      "price": 41000,
      "categorie": 1,
      "codigo":"B26",
      "descripcion":"10 unid. salmon crudo, pepino, aguacate",
      "image": require("../image/sushi/B26.png")
    },
    {
      "name": "Spicy Salmon Roll",
      "price": 48000,
      "categorie": 1,
      "codigo":"B27",
      "descripcion":"10 unid. salmon crudo, pepino, salsa picante",
      "image": require("../image/sushi/B27.png")
    },
    {
      "name": "Sushi Mixto",
      "price": 67000,
      "categorie": 1,
      "codigo":"10",
      "image": require("../image/sushi/10.png")
    },
    {
      "name": "Sushi Especial",
      "price": 110000,
      "categorie": 1,
      "codigo":"10E",
      "image": require("../image/sushi/10E.png")
    },
    {
      "name": "Aburi de Salmon",
      "price": 27000,
      "categorie": 1,
      "codigo":"10S",
      "image": require("../image/sushi/10S.png")
    },
    {
      "name": "Sushi de Salmon",
      "price": 53000,
      "categorie": 1,
      "codigo":"10A",
      "image": require("../image/sushi/10A.png")
    },
    {
      "name": "Salmon Maki",
      "price": 35000,
      "categorie": 1,
      "codigo":"B11",
      "image": require("../image/sushi/B11.png")
    },
    {
      "name": "Atun Maki",
      "price": 39000,
      "categorie": 1,
      "codigo":"B9",
      "image": require("../image/sushi/B9.png")
    },
    {
      "name": "Atun y Pepino Maki",
      "price": 36000,
      "categorie": 1,
      "codigo":"B12",
      "image": require("../image/sushi/B12.png")
    },
    {
      "name": "Sashimi de Salmon",
      "price": 59000,
      "categorie": 1,
      "codigo":"42A",
      "image": require("../image/sushi/42A.png")
    },
    {
      "name": "Sashimi de Pulpo",
      "price": 67000,
      "categorie": 1,
      "codigo":"42P",
      "image": require("../image/sushi/42P.png")
    },
    {
      "name": "Sashimi Mixto",
      "price": 65000,
      "categorie": 1,
      "codigo":"42",
      "image": require("../image/sushi/42.png")
    },
    {
      "name": "Pepino Maki",
      "price": 26000,
      "categorie": 1,
      "codigo":"B10",
      "image": require("../image/sushi/B10.png")
    },
    {
      "name": "Jengibre",
      "price": 4000,
      "categorie": 1,
      "codigo":"S",
      "image": require("../image/sushi/gari.png")
    },
    {
      "name": "Rainbow Roll",
      "price": 54000,
      "categorie": 1,
      "codigo":"B28",
      "descripcion":"10 unid. salmon crudo, queso, aguacate, variedad de pescado",
      "image": require("../image/sushi/B28.png")
    },
    {
      "name": "California Roll",
      "price": 40000,
      "categorie": 1,
      "codigo":"B17",
      "descripcion":"10 unid. salmon cocido, kanikama, huevo, pepino, lechuga",
      "image": require("../image/sushi/B17.png")
    },
    {
      "name": "Teriyaki Roll",
      "price": 42000,
      "categorie": 1,
      "codigo":"B18",
      "descripcion":"10 unid. salmon cocido, pepino",
      "image": require("../image/sushi/B18.png")
    },
    {
      "name": "Ebiten Roll",
      "price": 43000,
      "categorie": 1,
      "codigo":"B20",
      "descripcion":"10 unid. langostino frito, pepino",
      "image": require("../image/sushi/B20.png")
    },
    {
      "name": "Salmon Doble Roll",
      "price": 46000,
      "categorie": 1,
      "codigo":"B21",
      "descripcion":"10 unid. salmon crudo, salmon ahumado, pepino",
      "image": require("../image/sushi/B21.png")
    },
    {
      "name": "Vegetable Roll",
      "price": 32000,
      "categorie": 1,
      "codigo":"B30",
      "descripcion":"10 unid. queso, aguacate, zanahoria",
      "image": require("../image/sushi/B30.png")
    },
    {
      "name": "Makizushi",
      "price": 29000,
      "categorie": 1,
      "codigo":"B16",
      "descripcion":"10 unid. verdura, huevo, shitake",
      "image": require("../image/sushi/B16.png")
    },
    {
      "name": "Sukiyaki",
      "price": 65000,
      "categorie": 2,
      "codigo":"A",
      "descripcion":"carne, verdura, sopa",
      "image": require("../image/nabe/A.png")
    },
    {
      "name": "Yosenabe",
      "price": 74000,
      "categorie": 2,
      "codigo":"E",
      "descripcion":"carne, verdura, marisco, cab. salmon",
      "image": require("../image/nabe/E.png")
    },
    {
      "name": "Yosenabe Picante",
      "price": 79000,
      "categorie": 2,
      "codigo":"EP",
      "descripcion":"carne, verdura, marisco, cab. salmon",
      "image": require("../image/nabe/EP.png")
    },
    {
      "name": "Udon",
      "price": 10000,
      "categorie": 2,
      "codigo":"74",
      "image": require("../image/nabe/74.png")
    },
    {
      "name": "Beef curry",
      "price": 44000,
      "categorie": 5,
      "codigo":"13",
      "descripcion":"carne, cebolla, arroz, picante",
      "image": require("../image/arroz/13.png")
    },
    {
      "name": "Arroz frito con cerdo",
      "price": 32000,
      "categorie": 5,
      "codigo":"14",
      "image": require("../image/arroz/14.png")
    },
    {
      "name": "Arroz frito con camaron",
      "price": 41000,
      "categorie": 5,
      "codigo":"14A",
      "image": require("../image/arroz/14A.png")
    },
    {
      "name": "Arroz frito de Verdura",
      "price": 30000,
      "categorie": 5,
      "codigo":"14V",
      "image": require("../image/arroz/14V.png")
    },
    {
      "name": "Curry con milanesa de cerdo",
      "price": 45000,
      "categorie": 5,
      "codigo":"15",
      "image": require("../image/arroz/15.png")
    },
    {
      "name": "Omelete con arroz",
      "price": 35000,
      "categorie": 5,
      "codigo":"16",
      "image": require("../image/arroz/16.png")
    },
    {
      "name": "Hayashi rice",
      "price": 44000,
      "categorie": 5,
      "codigo":"17",
      "image": require("../image/arroz/17.png")
    },
    {
      "name": "Curry con arroz",
      "price": 37000,
      "categorie": 5,
      "codigo":"18",
      "image": require("../image/arroz/18.png")
    },
    {
      "name": "Oyakodon",
      "price": 35000,
      "categorie": 5,
      "codigo":"21",
      "descripcion":"pollo, verdura, huevo y arroz",
      "image": require("../image/arroz/21.png")
    },
    {
      "name": "Katsudon",
      "price": 36000,
      "categorie": 5,
      "codigo":"22",
      "descripcion":"milanesa de cerdo, huevo, verdura y arroz",
      "image": require("../image/arroz/22.png")
    },
    {
      "name": "Chashudon",
      "price": 37000,
      "categorie": 5,
      "codigo":"22C",
      "image": require("../image/arroz/22C.png")
    },
    {
      "name": "Tendon",
      "price": 40000,
      "categorie": 5,
      "codigo":"23",
      "descripcion":"langostino, verdura y arroz",
      "image": require("../image/arroz/23.png")
    },
    {
      "name": "Sukiyakidon",
      "price": 37000,
      "categorie": 5,
      "codigo":"24",
      "image": require("../image/arroz/24.png")
    },
    {
      "name": "Gyu-don",
      "price": 40000,
      "categorie": 5,
      "codigo":"24A",
      "image": require("../image/arroz/24A.png")
    },
    {
      "name": "Arroz blanco",
      "price": 8000,
      "categorie": 5,
      "codigo":"71",
      "image": require("../image/arroz/71.png")
    },
    {
      "name": "Tanmen",
      "price": 37000,
      "categorie": 4,
      "codigo":"37",
      "image": require("../image/fideo/37.png")
    },
    {
      "name": "Hakata Lamen",
      "price": 37000,
      "categorie": 4,
      "codigo":"39",
      "image": require("../image/fideo/39.png")
    },
    {
      "name": "Champon",
      "price": 45000,
      "categorie": 4,
      "codigo":"40",
      "image": require("../image/fideo/40.png")
    },
    {
      "name": "Champon Picante",
      "price": 47000,
      "categorie": 4,
      "codigo":"40P",
      "image": require("../image/fideo/40P.png")
    },
    {
      "name": "Shoyu Lamen",
      "price": 35000,
      "categorie": 4,
      "codigo":"38",
      "image": require("../image/fideo/38.png")
    },
    {
      "name": "Lamen Vegetariano",
      "price": 35000,
      "categorie": 4,
      "codigo":"39V",
      "image": require("../image/fideo/39V.png")
    },
    {
      "name": "Morisoba",
      "price": 33000,
      "categorie": 4,
      "codigo":"36",
      "image": require("../image/fideo/36.png")
    },
    {
      "name": "Su Udon",
      "price": 30000,
      "categorie": 4,
      "codigo":"29",
      "image": require("../image/fideo/29.png")
    },
    {
      "name": "Niku Udon",
      "price": 36000,
      "categorie": 4,
      "codigo":"29V",
      "image": require("../image/fideo/29V.png")
    },
    {
      "name": "Tempura Soba",
      "price": 38000,
      "categorie": 4,
      "codigo":"31",
      "image": require("../image/fideo/31.png")
    },
    {
      "name": "Tempura Udon",
      "price": 38000,
      "categorie": 4,
      "codigo":"32",
      "image": require("../image/fideo/32.png")
    },
    {
      "name": "Curry Udon",
      "price": 41000,
      "categorie": 4,
      "codigo":"33",
      "image": require("../image/fideo/33.png")
    },
    {
      "name": "Ankake Yakisoba",
      "price": 34000,
      "categorie": 4,
      "codigo":"34",
      "image": require("../image/fideo/34.png")
    },
    {
      "name": "Yakisoba Mixto",
      "price": 34000,
      "categorie": 4,
      "codigo":"34A",
      "image": require("../image/fideo/34A.png")
    },
    {
      "name": "Yakisoba Mixto con camaron",
      "price": 40000,
      "categorie": 4,
      "codigo":"34AL",
      "image": require("../image/fideo/34AL.png")
    },
    {
      "name": "Sara Lamen de Marisco Picante",
      "price": 46000,
      "categorie": 4,
      "codigo":"34P",
      "image": require("../image/fideo/34P.png")
    },
    {
      "name": "Yosenabe Udon",
      "price": 40000,
      "categorie": 4,
      "codigo":"27",
      "image": require("../image/fideo/27.png")
    },
    {
      "name": "Nabeyaki Udon",
      "price": 34000,
      "categorie": 4,
      "codigo":"28",
      "image": require("../image/fideo/28.png")
    },
    {
      "name": "Miso Lamen",
      "price": 40000,
      "categorie": 4,
      "codigo":"39M",
      "image": require("../image/fideo/39M.png")
    },
    {
      "name": "Ensalada de Alga",
      "price": 25000,
      "categorie": 7,
      "codigo":"65A",
      "image": require("../image/verdura/65A.png")
    },
    {
      "name": "Ensalada de Marisco",
      "price": 50000,
      "categorie": 7,
      "codigo":"65M",
      "image": require("../image/verdura/65M.png")
    },
    {
      "name": "Ensalada de Carne Vacuna",
      "price": 45000,
      "categorie": 7,
      "codigo":"65V",
      "image": require("../image/verdura/65V.png")
    },
    {
      "name": "Ensalada de Pollo Picante",
      "price": 38000,
      "categorie": 7,
      "codigo":"65P",
      "image": require("../image/verdura/65P.png")
    },
    {
      "name": "Ensalada de Salmon Cocinado",
      "price": 50000,
      "categorie": 7,
      "codigo":"65S",
      "image": require("../image/verdura/65S.png")
    },
    {
      "name": "Shimeji",
      "price": 38000,
      "categorie": 7,
      "codigo":"B1",
      "image": require("../image/verdura/B1.png")
    },
    {
      "name": "Verdura Salteado con Pollo",
      "price": 32000,
      "categorie": 7,
      "codigo":"63P",
      "image": require("../image/verdura/63P.png")
    },
    {
      "name": "Verdura Salteado",
      "price": 30000,
      "categorie": 7,
      "codigo":"63B",
      "image": require("../image/verdura/63B.png")
    },
    {
      "name": "Yakisoba solo de Verdura",
      "price": 32000,
      "categorie": 7,
      "codigo":"34AV",
      "image": require("../image/verdura/34AV.png")
    },
    {
      "name": "Okonomiyaki de Verdura",
      "price": 36000,
      "categorie": 7,
      "codigo":"N",
      "image": require("../image/bocadito/M.png")
    },
    {
      "name": "Arroz frito de Verdura",
      "price": 30000,
      "categorie": 7,
      "codigo":"14V",
      "image": require("../image/verdura/14V.png")
    },
    {
      "name": "Tempura de Verdura",
      "price": 33000,
      "categorie": 7,
      "codigo":"46B",
      "image": require("../image/verdura/46B.png")
    },
    {
      "name": "Tofu Frio",
      "price": 18000,
      "categorie": 7,
      "codigo":"67",
      "image": require("../image/verdura/67.png")
    },
    {
      "name": "Dengaku",
      "price": 22000,
      "categorie": 7,
      "codigo":"68",
      "image": require("../image/verdura/68.png")
    },
    {
      "name": "Aguedashi Tofu",
      "price": 22000,
      "categorie": 7,
      "codigo":"68A",
      "image": require("../image/verdura/68A.png")
    },
    {
      "name": "Sunomono",
      "price": 10000,
      "categorie": 7,
      "codigo":"64",
      "image": require("../image/verdura/64.png")
    },
    {
      "name": "Ensalada de Verdura",
      "price": 18000,
      "categorie": 7,
      "codigo":"65",
      "image": require("../image/verdura/65.png")
    },
    {
      "name": "Natto",
      "price": 13000,
      "categorie": 7,
      "codigo":"73",
      "image": require("../image/verdura/73.png")
    },
    {
      "name": "Sopa de Miso",
      "price": 8000,
      "categorie": 3,
      "codigo":"72",
      "image": require("../image/bocadito/72.png")
    },
    {
      "name": "Pulpo a la plancha",
      "price": 77000,
      "categorie": 3,
      "codigo":"B3",
      "image": require("../image/bocadito/B3.png")
    },
    {
      "name": "Tempura Mixto",
      "price": 40000,
      "categorie": 3,
      "codigo":"46",
      "image": require("../image/bocadito/46.png")
    },
    {
      "name": "Tempura de Langostino",
      "price": 81000,
      "categorie": 3,
      "codigo":"B4",
      "image": require("../image/pescado/B4.png")
    },
    {
      "name": "Calamar a la plancha",
      "price": 65000,
      "categorie": 3,
      "codigo":"B8",
      "image": require("../image/bocadito/B8.png")
    },
    {
      "name": "Croqueta",
      "price": 35000,
      "categorie": 3,
      "codigo":"54",
      "image": require("../image/bocadito/54.png")
    },
    {
      "name": "Calamar Fritos",
      "price": 50000,
      "categorie": 3,
      "codigo":"59",
      "image": require("../image/bocadito/59.png")
    },
    {
      "name": "Gyoza",
      "price": 27000,
      "categorie": 3,
      "codigo":"75",
      "image": require("../image/bocadito/75.png")
    },
    {
      "name": "Karaage",
      "price": 37000,
      "categorie": 3,
      "codigo":"76",
      "image": require("../image/bocadito/76.png")
    },
    {
      "name": "Okonomiyaki",
      "price": 43000,
      "categorie": 3,
      "codigo":"M",
      "image": require("../image/bocadito/M.png")
    },
    {
      "name": "Tempura de Verdura",
      "price": 33000,
      "categorie": 3,
      "codigo":"46B",
      "image": require("../image/verdura/46B.png")
    },
    {
      "name": "Tempura de Pollo",
      "price": 35000,
      "categorie": 3,
      "codigo":"46C",
      "image": require("../image/bocadito/46C.png")
    },
    {
      "name": "meuniere de anchoa",
      "price": 55000,
      "categorie": 8,
      "codigo":"B6A",
      "image": require("../image/pescado/B6A.png")
    },
    {
      "name": "meuniere de salmon",
      "price": 55000,
      "categorie": 8,
      "codigo":"B6S",
      "image": require("../image/pescado/B6S.png")
    },
    {
      "name": "Milanesa de Salmon",
      "price": 55000,
      "categorie": 8,
      "codigo":"44S",
      "image": require("../image/pescado/44S.png")
    },
    {
      "name": "Milanesa de Langostino",
      "price": 77000,
      "categorie": 8,
      "codigo":"43",
      "image": require("../image/pescado/43.png")
    },
    {
      "name": "Salmon a la parrila",
      "price": 55000,
      "categorie": 8,
      "codigo":"45S",
      "image": require("../image/pescado/45S.png")
    },
    {
      "name": "Anchoa a la parrila",
      "price": 55000,
      "categorie": 8,
      "codigo":"45A",
      "image": require("../image/pescado/45A.png")
    },
    {
      "name": "Teriyaki de Salmon",
      "price": 57000,
      "categorie": 8,
      "codigo":"48",
      "image": require("../image/pescado/48.png")
    },
    {
      "name": "Pulpo a la plancha",
      "price": 77000,
      "categorie": 8,
      "codigo":"B3",
      "image": require("../image/bocadito/B3.png")
    },
    {
      "name": "Tempura de Langostino",
      "price": 81000,
      "categorie": 8,
      "codigo":"B4",
      "image": require("../image/pescado/B4.png")
    },
    {
      "name": "Calamar a la plancha",
      "price": 65000,
      "categorie": 8,
      "codigo":"B8",
      "image": require("../image/bocadito/B8.png")
    },
    {
      "name": "C1 Set",
      "price": 21000,
      "categorie": 8,
      "codigo":"C1",
      "image": require("../image/carne/C1.png")
    },
    {
      "name": "C2 Set",
      "price": 21000,
      "categorie": 8,
      "codigo":"C2",
      "image": require("../image/carne/C2.png")
    },
    {
      "name": "C3 Set",
      "price": 21000,
      "categorie": 8,
      "codigo":"C3",
      "image": require("../image/carne/C3.png")
    },
    {
      "name": "Menu infantil",
      "price": 28000,
      "categorie": 9,
      "codigo":"12",
      "image": require("../image/kids/12.png")
    },
    {
      "name": "Sushi infantil",
      "price": 17000,
      "categorie": 9,
      "codigo":"12S",
      "image": require("../image/kids/12S.png")
    },
    {
      "name": "Yakiniku",
      "price": 43000,
      "categorie": 6,
      "codigo":"8",
      "image": require("../image/carne/8.png")
    },
    {
      "name": "Yakiniku con ajo y papas",
      "price": 46000,
      "categorie": 6,
      "codigo":"8A",
      "image": require("../image/carne/8A.png")
    },
    {
      "name": "Hamburguesa",
      "price": 35000,
      "categorie": 6,
      "codigo":"53",
      "image": require("../image/carne/53.png")
    },
    {
      "name": "Teriyaki de Pollo",
      "price": 32000,
      "categorie": 6,
      "codigo":"55P",
      "image": require("../image/carne/55P.png")
    },
    {
      "name": "Teriyaki de Carne",
      "price": 41000,
      "categorie": 6,
      "codigo":"55V",
      "image": require("../image/carne/55V.png")
    },
    {
      "name": "Teriyaki de Cerdo",
      "price": 34000,
      "categorie": 6,
      "codigo":"55C",
      "image": require("../image/carne/55P.png")
    },
    {
      "name": "Cerdo al Jengibre",
      "price": 34000,
      "categorie": 6,
      "codigo":"56C",
      "image": require("../image/carne/56C.png")
    },
    {
      "name": "Pollo al Jengibre",
      "price": 32000,
      "categorie": 6,
      "codigo":"56P",
      "image": require("../image/carne/56P.png")
    },
    {
      "name": "Carne Vacuna al Jengibre",
      "price": 41000,
      "categorie": 6,
      "codigo":"56V",
      "image": require("../image/carne/56V.png")
    },
    {
      "name": "Milanesa de Cerdo",
      "price": 34000,
      "categorie": 6,
      "codigo":"50C",
      "image": require("../image/carne/50.png")
    },
    {
      "name": "Milanesa de Pollo",
      "price": 32000,
      "categorie": 6,
      "codigo":"50P",
      "image": require("../image/carne/50.png")
    },
    {
      "name": "C1 Set",
      "price": 21000,
      "categorie": 6,
      "codigo":"C1",
      "image": require("../image/carne/C1.png")
    },
    {
      "name": "C2 Set",
      "price": 21000,
      "categorie": 6,
      "codigo":"C2",
      "image": require("../image/carne/C2.png")
    },
    {
      "name": "C3 Set",
      "price": 21000,
      "categorie": 6,
      "codigo":"C3",
      "image": require("../image/carne/C3.png")
    },
    {
      "name": "Agua sin Gas",
      "price": 7000,
      "categorie": 10,
      "codigo":"G1",
      "image": require("../image/bebida/aguasingas.png")
    },
    {
      "name": "Agua con Gas",
      "price": 7000,
      "categorie": 10,
      "codigo":"G1",
      "image": require("../image/bebida/aguacongas.png")
    },
    {
      "name": "Coca 500ml",
      "price": 9000,
      "categorie": 10,
      "codigo":"G0",
      "image": require("../image/bebida/coca.png")
    },
    {
      "name": "Coca Zero 500ml",
      "price": 9000,
      "categorie": 10,
      "codigo":"G0",
      "image": require("../image/bebida/cocazero.png")
    },
    {
      "name": "Heineken 3/4",
      "price": 23000,
      "categorie": 10,
      "codigo":"C4",
      "image": require("../image/bebida/heineken.png")
    },
    {
      "name": "Patagonia",
      "price": 27000,
      "categorie": 10,
      "codigo":"C23",
      "image": require("../image/bebida/patagonia.png")
    }


  ]

}
